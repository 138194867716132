
  import { computed, defineComponent } from 'vue';
  import ModalWrapper from '@/components/modals/ModalWrapper.vue';
  import { useRoute, useRouter } from 'vue-router';
  import vehicles from '@/services/vehicles';
  import brand from '@/services/brand';
  import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
  import vehicleBrands from "@/services/vehicleBrands";
  import vehicleModels from "@/services/vehicleModels";

  export default defineComponent({
    name: 'ConfirmModalDeleteMasteringSheet',
    components: { ModalWrapper },
    props: {
      isShowModal: {
        type: Boolean,
        default: () => false
      },
      vehicleInfo: {
        type: String,
        required: true,
        default: () => null,
      },
      checked: {
        type: Array,
        required: true,
        default: () => null,
      },
    },
    methods: {
      successAction() {
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
        setTimeout(() => {
          this.$emit('success')
        }, 1000)
      },
      closeModal() {
        const element = document.getElementById(`modal-confirm-modal`)!;
        element.style.display = 'none';
        this.$emit('closeModal');
      },

    },
    data: () => ({
    }),
  });
