
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import MasteringSheet from "@/services/MasteringSheet";
import Spinner from '@/components/Spinner.vue';
import router from "@/router";
import {ConvertDate} from "@/components/converters/date";
import {useStore} from "vuex";
import DropdownEllipsis from "@/components/admin-panel/DropdownEllipsis.vue";
import ConfirmModalDeleteMasteringSheet
  from "@/components/modals/ConfirmModalDeleteMasteringSheet.vue";
import BaseIcon from '@/components/ui/BaseIcon.vue';
import BaseTable from '@/components/ui/BaseTable.vue';

export default defineComponent({
  name: 'DevelopmentSheets',
  components: {
    BaseTable,
    BaseIcon,
    Spinner,
    DropdownEllipsis,
    ConfirmModalDeleteMasteringSheet
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false as boolean);
    const currentMasteringSheet = ref()
    const content = ref([] as Array<any>);

    const pageable = ref({
      page: 0,
      totalPages: 0,
      sort: 'desc'
    })

    const tableHeader = ['Наименование', 'Дата создания', 'Количество артикулов', 'Автор'];
    const convertDate = computed(() => {
      return (item: any) => ConvertDate(item)
    });
    const getUserName = computed(() => {
      return (id: number) => store.getters.getLoggedUser?.username
    });

    const checkUserRole = computed(() => {
      return store.getters.getAllPermissions.create_mastering_sheet[store.getters.getUserRole]
    })


    const getDropdownOptions = computed(() => (item: any) => {
      const options = []

      if (store.getters.getAllPermissions.edit_mastering_sheet[store.getters.getUserRole]) {
        options.push({
          name: 'Редактировать',
          method: () => editSheet(item)
        })
      }

      if (store.getters.getAllPermissions.delete_mastering_sheet[store.getters.getUserRole]) {
        options.push({
          name: 'Удалить',
          method: () => openModal(item)
        })
      }

      return options
    })

    function goToNewList() {
      router.push({name: 'create-sheet'})
    }

    function goToList($event: any, item: any) {
      if ($event.target.id === 'dropdown-ellipsis' || $event.target.className === 'dropdown__item') {
        return
      }
      router.push(`/sheet/${item.id}?name=${item.name}`)
    }

    function editSheet(item: any) {
      router.push(`/sheet/new/${item.id}`)
    }

    async function getSheets(nextPage: any, pageSort = 'desc'): Promise<any> {
      try {
        const sheets = await MasteringSheet.getSheets(nextPage, pageSort)

        pageable.value = {
          ...pageable.value,
          totalPages: sheets.totalPages,
          page: sheets.pageable.pageNumber,
        }

        return sheets && sheets.content
      } catch (error) {
        console.error(error)
      }
    }

    async function setDefaultData() {
      try {
        isLoading.value = true
        content.value = await getSheets(pageable.value.page, pageable.value.sort);
        isLoading.value = false
      } catch (error) {
        isLoading.value = false;
        throw error
      }
    }

    async function openModal(item: any) {
      currentMasteringSheet.value = item
      let element = document.getElementById(`modal-confirm-modal`)!
      element.style.display = 'flex'
    }

    async function closeModal() {
      currentMasteringSheet.value = null
      let element = document.getElementById(`modal-confirm-modal`)!
      element.style.display = 'none'
    }

    async function deleteSheet() {
      if (currentMasteringSheet.value) {
        await MasteringSheet.deleteMasteringSheet(currentMasteringSheet.value.id)
        isLoading.value = true
        await setDefaultData()
        currentMasteringSheet.value = null
        closeModal()
      }
    }

    function previous() {
      if (pageable.value.page) {
        pageable.value.page -= 1
      }
    }

    function next() {
      if (pageable.value.page + 1 !== pageable.value.totalPages) {
        pageable.value.page += 1
      }
    }

    watch(() => pageable.value.page, () => {
      setDefaultData()
    });

    watch(() => pageable.value.sort, () => {
      setDefaultData()
    });

    const headers: any = computed(() => {
      return [
        {
          title: 'Наименования',
          content: (item: any) => item.name
        },
        {
          title: 'Дата создания',
          content: (item: any) => convertDate.value(item.date)
        },
        {
          title: 'Количество артикулов',
          content: (item: any) => item && item.articles && item.articles.length
        },
        {
          title: 'Автор',
          content: (item: any) => getUserName.value(item.userId)
        },
      ]
    });

    function setSort() {
      pageable.value.sort = pageable.value.sort === 'asc'
        ? 'desc'
        : 'asc'
    }

    onMounted(async () => {
      isLoading.value = true;
      let loggedUser = localStorage.getItem('loggedUser');
      let cid = localStorage.getItem('cid');
      if (loggedUser && cid) {
        await setDefaultData()
      } else {
        await router.push('login')
      }
    });

    return {
      deleteSheet,
      getDropdownOptions,
      isLoading,
      content,
      tableHeader,
      convertDate,
      getUserName,
      goToNewList,
      goToList,
      checkUserRole,
      editSheet,
      previous,
      next,
      headers,
      setSort,
      pageable
    }
  }
})
